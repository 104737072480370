import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { PageLayout, PageLink, GiftListBlockService } from '../../components'
import { l } from '../../lib/locale'

const { pages } = require('../../config/pages.json')
const buyPage = pages.find(i => i.id === 'giftlist-buy')
const managePage = pages.find(i => i.id === 'my-giftlists')
const createPage = pages.find(i => i.id === 'giftlist-create')

const Image = ({ src, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: allFile(filter: {relativeDirectory: {eq: "giftlist"}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const file = data.placeholderImage.edges.find(({ node }) => node.childImageSharp.fluid.originalName === src)
  if (file) {
    return <Img fluid={file.node.childImageSharp.fluid} />
  }
  return ''
}

const GiftListIndexTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  return (
    <PageLayout lang={lang} switcher={page} className="giftlist">
      <div className="giftlist-hero">
        <h1>{l('giftlist', lang)}</h1>
        <p>{l('giftlist-description', lang)}</p>
      </div>

      <div className="giftlist-options">
        <div className="row">
          <div className="col-md-4 giftlist-options-col">
            <PageLink to={buyPage[`path_${lang.suffix}`]}>
              <Image src="giftlist_1.png" />
              <h2 className="center">Koop een cadeau van een lijst</h2>
            </PageLink>
          </div>
          <div className="col-md-4 giftlist-options-col">
            <PageLink to={managePage[`path_${lang.suffix}`]}>
              <Image src="giftlist_2.png" />
              <h2 className="center">Beheer jouw wenslijst</h2>
            </PageLink>
          </div>
          <div className="col-md-4 giftlist-options-col">
            <PageLink to={createPage[`path_${lang.suffix}`]}>
              <Image src="giftlist_3.png" />
              <h2 className="center">Maak een nieuwe lijst aan</h2>
            </PageLink>
          </div>
        </div>
      </div>

      <GiftListBlockService lang={lang} />
    </PageLayout>
  )
}

export default GiftListIndexTemplate
